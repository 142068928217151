import React, { useContext, useEffect, useState } from "react";
import ScoreBoard from "../ScoreBoard/ScoreBoard";
import Button from "@mui/material/Button";
import FlagIcon from "@mui/icons-material/Flag";
import { RoomContext } from "src/modules/RoomPage/RoomPage";
import { releaseWakeLock } from "../../wakelock";
import { SocialLinks } from "../SocialLinks/SocialLinks";
import * as API from "../../api/roomAPI";
import "./styles.scss";
import "animate.css";

const FinalScoreBoard: React.FC = () => {
  const { room } = useContext(RoomContext);
  const [finalScoreView, setFinalScoreView] = useState(1);

  useEffect(() => {
    const setScoreViewTimer = setTimeout(() => setFinalScoreView(2), 2000);
    releaseWakeLock();
    return () => {
      clearTimeout(setScoreViewTimer);
    };
  }, []);

  const onStartAgainQuizButton = () => {
    API.playQuizAgain(room);
  };

  return (
    <div className="FinalScoreBoard">
      {finalScoreView === 1 && (
        <div>A więc, to już koniec! Za chwilę ogłosimy wyniki! 🥹</div>
      )}

      {finalScoreView === 2 && (
        <>
          <ScoreBoard
            userWithPoints={room.usersIn.sort((a, b) => b.points - a.points)}
          />

          <div className="FinalScoreBoard__startAgainButton">
            <Button
              className=""
              variant="outlined"
              color="info"
              size="large"
              startIcon={<FlagIcon />}
              onClick={onStartAgainQuizButton}
            >
              Zagrajmy jeszcze raz
            </Button>
            <SocialLinks />
          </div>
        </>
      )}
    </div>
  );
};

export default FinalScoreBoard;
