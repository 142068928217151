import { FC, useContext, useRef, forwardRef } from "react";
import { TextField } from "@mui/material";
import { Form, Field } from "react-final-form";
import { RoomContext } from "../../RoomPage";
import * as API from "../../../../api/roomAPI";
import * as C from "src/helpers/constants";
import * as S from "./styles";

interface CodeFormProps {
  userID: string;
}

const CodeForm: FC<CodeFormProps> = forwardRef(({ userID }, ref) => {
  const { room } = useContext(RoomContext);
  const { code, codePart, codeKey } =
    room.currentQuestion.userTraps[userID].code;
  const hiddenFields = codePart === 0 ? [3, 4, 5] : [0, 1, 2];

  const initialValues = code
    .toString()
    .split("")
    .map((dig, i) => (hiddenFields.includes(i) ? "" : dig));

  const handleSubmit = (values) => {
    if (code === values.digits.join("")) {
      API.clearCodeTrap(userID, room);
    }
  };

  const inputsRef = useRef<(HTMLInputElement | null)[]>([]);

  const Icon = C.shapes[codeKey.shape];

  return (
    <span ref={ref as React.LegacyRef<HTMLDivElement>}>
      <Form
        onSubmit={handleSubmit}
        initialValues={{ digits: initialValues }}
        render={({ handleSubmit, values }) => (
          <S.Form onChange={() => handleSubmit(values)}>
            <S.Container>
              <S.Text>Tajny kod... ktoś ma brakującą część...</S.Text>
              {Array.from({ length: 6 }).map((_, index) => (
                <Field
                  name={`digits[${index}]`}
                  parse={(value) =>
                    (value as string).replace(/[^0-9]/g, "").slice(0, 1)
                  }
                >
                  {({ input }) => (
                    <S.FieldWrapper>
                      <TextField
                        {...input}
                        color="warning"
                        disabled={!hiddenFields.includes(index)}
                        type="text"
                        inputProps={{
                          maxLength: 1,
                          sx: {
                            height: 60,
                            textAlign: "center",
                            fontSize: 36,
                          },
                        }}
                        variant="outlined"
                        size="small"
                        sx={{
                          width: "80px",
                        }}
                        inputRef={(el) => (inputsRef.current[index] = el)}
                        onChange={(e) => {
                          input.onChange(e);
                          const value = e.target.value;
                          if (
                            value.length === 1 &&
                            index < 5 &&
                            !inputsRef.current[index + 1].value
                          ) {
                            inputsRef.current[index + 1]?.focus();
                          }
                        }}
                        onFocus={() => {
                          if (input.value) {
                            input.onChange("");
                          }
                        }}
                      />
                    </S.FieldWrapper>
                  )}
                </Field>
              ))}
              <Icon
                sx={{
                  marginTop: 4,
                  width: 60,
                  height: 60,
                  color: codeKey.color,
                  boxShadow: "0 0 20px #8a8a8a",
                  borderRadius: "10px",
                  padding: "6px",
                }}
              />
            </S.Container>
          </S.Form>
        )}
      />
    </span>
  );
});

export default CodeForm;
