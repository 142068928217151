/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import useUserAnswerPoints from "../../../helpers/useUserAnswerPoints";
import FinalScoreBoard from "../../../components/FinalScoreBoard/FinalScoreBoard";
import RightSide from "./components/RightSide/RightSide";
import LeftSide from "./components/LeftSide/LeftSide";
import Time from "../../../components/Time/Time";
import logo from "../../../assets/logo.png";
import { RoomContext } from "../RoomPage";
import * as API from "../../../api/roomAPI";
import "./styles.scss";

const HostView: React.FC = () => {
  let pushNextQuestionTimer: NodeJS.Timeout;
  const { room } = useContext(RoomContext);
  const { ID: roomID } = useParams() as { ID: string };
  const { userWithPoints } = useUserAnswerPoints(roomID);

  // On finish question:
  useEffect(() => {
    if (room?.currentQuestion?.isFinished) {
      pushNextQuestionTimer = setTimeout(() => {
        API.pushPointsToUsersReq(room!, roomID!, userWithPoints);
        process.env.NODE_ENV === "production" &&
          API.pushQuestionReq(roomID, room);
      }, 10000);
      return () => {
        clearTimeout(pushNextQuestionTimer);
      };
    }
  }, [room?.currentQuestion?.isFinished]);

  const onTimerExpaired = () => {
    process.env.NODE_ENV === "production"
      ? API.finishQuestion(roomID)
      : console.log("Question Finished!");
  };

  // DEVELOP MODE
  const onStartAgainQuizButton = () => {
    clearTimeout(pushNextQuestionTimer);
    API.playQuizAgain(room!);
  };

  const onNext = () => {
    API.pushQuestionReq(room?.id!, room!);
  };
  // DEVELOP MODE

  return (
    <div className="RoomPage__container">
      <Time onTimerExpaired={onTimerExpaired} />

      <div className="RoomPage__side_container">
        {room?.isQuizEnd ? (
          <FinalScoreBoard />
        ) : (
          <>
            <LeftSide />
            <RightSide />
            {!room?.currentQuestion && (
              <img
                className="RoomPage__side_container__logo"
                src={logo}
                alt="logo"
                width={200}
              />
            )}
          </>
        )}
      </div>

      {process.env.NODE_ENV !== "production" && (
        <>
          <button onClick={onStartAgainQuizButton}>RESTART</button>
          <button onClick={onNext}>Next</button>
        </>
      )}
    </div>
  );
};

export default HostView;
