import shuffle from "lodash.shuffle";
import { set, ref } from "firebase/database";
import { IRoom, Traps } from "src/helpers/types";
import { db } from "../firebase";
import * as C from "../helpers/constants";

export const trapsKeys = (room: IRoom) => {
  return (Object.keys(C.trapList) as Traps[]).filter((trap) => {
    if (
      room.config.allPositive &&
      (!C.trapList[trap].isPositive || trap === "shield" || trap === "clean")
    ) {
      return false;
    }
    if (room.config.noCodeTrap && trap === "code") {
      return false;
    }
    return true;
  });
};

export const runTrap = (room: IRoom, userID: string, userFromID: string) => ({
  clean: async () => {
    await set(
      ref(db, `/rooms/${room.id}/currentQuestion/userTraps/${userID}/`),
      {},
    );
  },
  joker: async () => {
    const correctAnswer =
      room.questions[room.currentQuestion.qNumber].answers[0];
    await set(
      ref(db, `/rooms/${room.id}/currentQuestion/userAnswers/${userID}`),
      {
        name: correctAnswer,
        time: new Date().getTime(),
        isCorrectByTrap: true,
      },
    );

    runTrap(room, userID, userFromID).default("joker");
  },
  code: async () => {
    const code = Math.floor(Math.random() * 1000000)
      .toString()
      .padStart(6, "0");

    const userWithID = shuffle(
      room.usersIn.filter(
        (user) =>
          user.id !== userID &&
          user.id !== userFromID &&
          !room.currentQuestion.userTraps?.[user.id]?.shield,
      ),
    )?.[0]?.id;

    const codeKey = {
      color: shuffle(C.simpleColors)[0],
      shape: shuffle(Object.keys(C.shapes))[0],
    };

    if (userWithID) {
      await set(
        ref(db, `/rooms/${room.id}/currentQuestion/userTraps/${userID}/code`),
        {
          userFromID,
          code,
          codePart: 0,
          codeKey,
        },
      );

      await set(
        ref(
          db,
          `/rooms/${room.id}/currentQuestion/userTraps/${userWithID}/code`,
        ),
        {
          code,
          codePart: 1,
          codeKey,
        },
      );
    }
  },
  default: async (trap: Traps) => {
    await set(
      ref(db, `/rooms/${room.id}/currentQuestion/userTraps/${userID}/${trap}`),
      {
        userFromID,
      },
    );
  },
});
