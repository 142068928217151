import { useEffect, useState, useCallback, useContext } from "react";
import { IUserWithPoints } from "./types";
import * as API from "../api/roomAPI";
import { RoomContext } from "src/modules/RoomPage/RoomPage";

export const useUserAnswerPoints = (
  roomID: string,
): { userWithPoints: IUserWithPoints[] } => {
  const { room } = useContext(RoomContext);
  const [userWithPoints, setUserWithPoints] = useState<IUserWithPoints[]>([]);

  const calculatePoints = useCallback(() => {
    if (room) {
      const userPreviewPointsArray: IUserWithPoints[] = [];

      for (const user of room.usersIn) {
        let points = 0;

        const userAnswer = room.currentQuestion?.userAnswers?.[user.id];

        if (
          userAnswer?.name ===
          room.questions?.[room.currentQuestion.qNumber].answers[0]
        ) {
          const timeDiff =
            100 -
            Math.round(
              ((userAnswer.time - room.currentQuestion.qtime) / 10) * 0.05,
            );

          if (timeDiff >= 50 && !userAnswer.isCorrectByTrap) {
            points = timeDiff;
          } else {
            points = 50;
          }
        }

        userPreviewPointsArray.push({
          id: user.id,
          points,
        });
      }

      if (
        Object.keys(room?.currentQuestion?.userAnswers || {})?.length ===
        room?.usersIn?.length
      ) {
        API.finishQuestion(roomID);
      }

      setUserWithPoints(
        userPreviewPointsArray.sort((a, b) => b.points - a.points),
      );
    }
  }, [room, roomID]);

  useEffect(() => {
    if (room?.currentQuestion?.answers) {
      calculatePoints();
    }
  }, [calculatePoints, room?.currentQuestion?.answers]);

  useEffect(() => {
    setUserWithPoints([]);
  }, [room?.currentQuestion?.qNumber]);

  return { userWithPoints };
};

export default useUserAnswerPoints;
