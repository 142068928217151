import Styled from "styled-components";

export const Form = Styled.form`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
`;

export const Text = Styled.div`
    text-align: center;
    margin-bottom: 40px;
    font-size: 30px;
    font-family: fantasy;
`;

export const Container = Styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center; 
    gap: 10px;
    max-width: 300px;
`;

export const FieldWrapper = Styled.div`
    flex: 1 1 calc(33.333% - 10px); 
    box-sizing: border-box; 
    text-align: center;
    padding: 5px;
`;
