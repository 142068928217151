import React, { useContext, useEffect, useState } from "react";
import cx from "classnames";
import { IUserIn, IUserWithPoints } from "../../helpers/types";
import ScoreBoard from "../ScoreBoard/ScoreBoard";
import calculatePng from "../../assets/calculate.png";
import { RoomContext } from "src/modules/RoomPage/RoomPage";
import S from "./styles.module.scss";
import "animate.css";

interface QuestionScoreBoardProps {
  userWithPoints: IUserWithPoints[];
  usersIn: IUserIn[];
}

const QuestionScoreBoard: React.FC<QuestionScoreBoardProps> = ({
  userWithPoints,
  usersIn,
}) => {
  const { room } = useContext(RoomContext);
  const [questionScoreView, setQuestionScoreView] = useState(1);

  useEffect(() => {
    if (userWithPoints) {
      const setScoreViewTimer = setTimeout(() => setQuestionScoreView(2), 3000);
      return () => {
        clearTimeout(setScoreViewTimer);
      };
    }
  }, [userWithPoints]);

  const usersWithPointsTransformed = userWithPoints.map((user) => {
    const { name, avatarID } =
      usersIn.find((userIn) => userIn.id === user.id) || {};
    return { ...user, name, avatarID };
  });

  return (
    <div className={S.Container}>
      {questionScoreView === 1 && (
        <div
          className={cx(S.PreText, "animate__animated animate__jackInTheBox")}
        >
          <img src={calculatePng} alt="Calculating points" />
          <div>OK mamy to! 🔥</div>
          <div>Czas na podliczenie punktów 🤔</div>
        </div>
      )}

      {questionScoreView === 2 && (
        <ScoreBoard userWithPoints={usersWithPointsTransformed} />
      )}
    </div>
  );
};

export default QuestionScoreBoard;
