import styled from "styled-components";

export const Container = styled.div<{ $relative: boolean }>`
  @media (max-height: 800px) {
    position: relative;
    bottom: 0;
  }

  position: ${({ $relative }) => ($relative ? "relative" : "absolute")};
  bottom: ${({ $relative }) => ($relative ? "0" : "40px")};
  display: flex;
  justify-content: center;
  gap: 20px;
  left: 0;
  right: 0;
`;
