import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../firebase";

export const getQuestionImage = async (imageId: string) =>
  // FIXIT: This is a bad practice to use multiple catch blocks
  getDownloadURL(ref(storage, `questionImages/${imageId}.jpg`))
    .then((url) => url)
    .catch(() =>
      getDownloadURL(ref(storage, `questionImages/${imageId}.png`))
        .then((url) => url)
        .catch(() =>
          getDownloadURL(ref(storage, `questionImages/${imageId}.jpeg`))
            .then((url) => url)
            .catch(() =>
              getDownloadURL(ref(storage, `questionImages/${imageId}.webp`))
                .then((url) => url)
                .catch((err) => {
                  console.log("Error getting image URL: ", err);
                  return "";
                }),
            ),
        ),
    );
