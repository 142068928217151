let wakeLock = null;

const requestWakeLock = async () => {
  try {
    if ("wakeLock" in navigator) {
      wakeLock = await (navigator as any).wakeLock.request("screen");
      console.log("Wake Lock is active.");
    } else {
      console.log("Wake Lock API is not supported in this browser.");
    }
  } catch (err) {
    console.error("Failed to acquire Wake Lock:", err);
  }
};

export const releaseWakeLock = () => {
  if (wakeLock) {
    wakeLock.release();
    wakeLock = null;
    console.log("Wake Lock released manually.");
  }
};

requestWakeLock();
