import { createTheme, SvgIconTypeMap, ThemeOptions } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import ScreenRotationIcon from "@mui/icons-material/ScreenRotation";
import MobileScreenShareIcon from "@mui/icons-material/MobileScreenShare";
import HideSourceIcon from "@mui/icons-material/HideSource";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import SecurityIcon from "@mui/icons-material/Security";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import PinIcon from "@mui/icons-material/Pin";
import BarChartIcon from "@mui/icons-material/BarChart";

import geoIcon from "../assets/categories/geo.svg";
import christmasIcon from "../assets/categories/christmas.svg";
import moviesIcon from "../assets/categories/movies.svg";
import historyIcon from "../assets/categories/history.svg";
import buildingsIcon from "../assets/categories/buildings.svg";
import musicIcon from "../assets/categories/music.svg";
import iqIcon from "../assets/categories/iq.svg";
import laughIcon from "../assets/categories/laugh.svg";
import seriesIcon from "../assets/categories/series.svg";
import biologyIcon from "../assets/categories/biology.svg";
import simplyIcon from "../assets/categories/simply.svg";
import basicIcon from "../assets/categories/basic.svg";
import pelikanIcon from "../assets/categories/pelikan.svg";

import FavoriteIcon from "@mui/icons-material/Favorite";
import CircleIcon from "@mui/icons-material/Circle";
import SquareIcon from "@mui/icons-material/Square";
import HexagonIcon from "@mui/icons-material/Hexagon";
import StarIcon from "@mui/icons-material/Star";

import { Traps } from "./types";

const defaultTheme = {
  typography: {
    fontFamily: "Raleway, Arial",
    fontSize: 24,
  },
  palette: {
    mode: "dark",
    primary: {
      main: "#e0a000",
    },
  },
};

export const hostTheme = createTheme({
  ...(defaultTheme as ThemeOptions),
  typography: {
    ...defaultTheme.typography,
    fontSize: 24,
  },
});

export const playerTheme = createTheme({
  ...(defaultTheme as ThemeOptions),
  typography: {
    ...defaultTheme.typography,
    fontSize: 18,
  },
});

export const maxQuestionNumber = 10;

export const timeCountSec = 30;

export const timeForAnswersShowUp = 15000;
// Repeat change in: components/TimeShort/styles.scss

export const trapList: Record<
  Traps,
  {
    name: Traps;
    title: string;
    icon: OverridableComponent<SvgIconTypeMap>;
    isPositive?: boolean;
    description?: string;
  }
> = {
  shield: {
    name: "shield",
    title: "Nietykalny",
    icon: SecurityIcon,
    isPositive: true,
    description:
      "Chroni przed działaniem innych min do końca aktualnego pytania.",
  },
  clean: {
    name: "clean",
    title: "Wyczyść",
    icon: CleaningServicesIcon,
    isPositive: true,
    description:
      "Usuwa wszystkie nałożone dotychczas efekty min na użytkownika.",
  },
  fiftyFifty: {
    name: "fiftyFifty",
    title: "Pół na pół",
    icon: DragHandleIcon,
    isPositive: true,
    description:
      "Pozostawia tylko jedną prawidłową i jedną nieprawidłową odpowiedź, zwiększając szanse na wybór właściwej.",
  },
  joker: {
    name: "joker",
    title: "Prawidłowa odpowiedź",
    icon: DoneOutlineIcon,
    isPositive: true,
    description:
      "Automatycznie wybiera prawidłową odpowiedź w bieżącym pytaniu.",
  },
  votes: {
    name: "votes",
    title: "Głos większości",
    icon: BarChartIcon,
    isPositive: true,
    description:
      "Pokazuje statystyki sugerujące najczęściej wybieraną odpowiedź przez innych graczy.",
  },

  rotateScreen: {
    name: "rotateScreen",
    title: "Odwrócony ekran",
    icon: ScreenRotationIcon,
    description:
      "Ekran gry zostaje odwrócony, co utrudnia odczytanie treści pytania i odpowiedzi.",
  },
  hideLetters: {
    name: "hideLetters",
    title: "Znikające literki",
    icon: HideSourceIcon,
    description:
      "Litery w tekście odpowiedzi zaczynają znikać, utrudniając zrozumienie słów.",
  },
  mirrorWord: {
    name: "mirrorWord",
    title: "Lustrzane słowa",
    icon: MobileScreenShareIcon,
    description:
      "Tekst odpowiedzi jest odwrócony lustrzanie, co sprawia, że trudno go odczytać.",
  },
  doubling: {
    name: "doubling",
    title: "Podwojenie odpowiedzi",
    icon: PlaylistAddIcon,
    description:
      "Dodaje dwie nieprawidłowe odpowiedzi, zwiększając liczbę opcji i poziom trudności.",
  },
  code: {
    name: "code",
    title: "Sparuj kod",
    icon: PinIcon,
    description:
      "Wybrany użytkownik i jeden losowy otrzymują tajny szyfr, który muszą sobie nawzajem przekazać, aby odblokować ekran.",
  },
};

export const categoriesIcons = {
  christmas: christmasIcon,
  basic: basicIcon,
  geo: geoIcon,
  simply: simplyIcon,
  movies_words: moviesIcon,
  geo_buildings: buildingsIcon,
  history: historyIcon,
  music: musicIcon,
  funny: laughIcon,
  IQ: iqIcon,
  serials_keys: seriesIcon,
  biology: biologyIcon,
  pelikan: pelikanIcon,
};

export const fencyColors = [
  "#1e6359",
  "#A77451",
  "#55214F",
  "#274e26",
  "#25306F",
  "#223030",
  "#523D35",
  "#371d6d",
  "#3d1d1d",
];

export const randomWords = [
  "dziurka",
  "prześmiewcy",
  "gęba",
  "duplikować",
  "różnobarwny",
  "eliminator",
  "antyklerykalizm",
  "hicks",
  "pokawałkować",
  "wielomowny",
  "ekonomia",
  "egzemplifikacja",
  "efemeryczny",
  "moskiewski",
  "ruchliwy",
];

export const simpleColors = ["red", "blue", "yellow", "green", "white"];

export const shapes = {
  heart: FavoriteIcon,
  circle: CircleIcon,
  hexagon: HexagonIcon,
  star: StarIcon,
  square: SquareIcon,
};
