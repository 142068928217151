import React, { useContext, useMemo } from "react";
import { useParams, useLocation } from "react-router-dom";
import cx from "classnames";
import shuffle from "lodash.shuffle";
import Button from "@mui/material/Button";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { Modal } from "@mui/material";
import { SocialLinks } from "src/components/SocialLinks/SocialLinks";
import Time from "../../../components/Time/Time";
import { IRoom } from "../../../helpers/types";
import logo from "../../../assets/logo.png";
import SliderAvatars from "../../../components/SliderAvatars/SliderAvatars";
import ChooseTrapsSnack from "../../../components/ChooseTrapsSnack/ChooseTrapsSnack";
import TrapsNotification from "../../../components/TrapsNotification/TrapsNotification";
import arrow from "../../../assets/arrow1.png";
import TimeShort from "../../../components/TimeShort/TimeShort";
import { RoomContext } from "../RoomPage";
import {
  fillAnswerWithTraps,
  getActiveTraps,
  getPercentsOfAnswers,
} from "./helpers";
import CodeForm from "./components/CodeForm";
import * as API from "../../../api/roomAPI";
import * as H from "../helpers";
import * as C from "../../../helpers/constants";
import S from "./styles.module.scss";
import { useSwipeable } from "react-swipeable";

const PlayerView: React.FC = () => {
  const { room } = useContext(RoomContext);
  const { ID: roomID } = useParams<{ ID: string }>();
  const { state } = useLocation<{ user: string }>();
  const userID = state?.user;

  const handlers = useSwipeable({
    onSwiped: (eventData) => console.log("User Swiped!", eventData),
  });

  const user = useMemo(
    () => room?.usersIn.find((user) => user.id === userID)!,
    [room?.usersIn, userID],
  );

  const onChosenAnswer = (answer: string) => () => {
    API.pushAnswerReq(answer, roomID!, userID);
  };

  const onSetReadyButton = () => {
    API.setReadyPlayer(userID, room!);
  };

  const playerAnswerValue = room?.currentQuestion?.userAnswers?.[userID]?.name;
  const isReady = H.getUserFromUsersIn(userID, room)?.isReady;

  const isTrap = getActiveTraps(userID, room);

  const isNotOptionFirstOrSecond = (answer: string, room: IRoom) =>
    answer !== room.questions?.[room.currentQuestion.qNumber].answers[0] &&
    answer !== room.questions?.[room.currentQuestion.qNumber].answers[1];

  const manipulatedAnswers = useMemo(() => {
    if (isTrap.doubling && room?.currentQuestion?.answers) {
      const get2RandomWords = shuffle(C.randomWords).slice(0, 2);
      return shuffle([...room?.currentQuestion?.answers, ...get2RandomWords]);
    }
    return room?.currentQuestion?.answers;
  }, [isTrap.doubling, room?.currentQuestion?.answers?.[0]]);

  const percentsOfAnswers = useMemo(() => {
    return getPercentsOfAnswers(room?.currentQuestion?.userAnswers);
  }, [room?.currentQuestion?.userAnswers]);

  const isThisCorrectAnswer = (answer: string) =>
    room.currentQuestion.isFinished &&
    answer === room.questions?.[room.currentQuestion.qNumber].answers[0];

  return (
    <div
      {...handlers}
      className={cx(S.Container, { [S.IsRotate]: isTrap.rotateScreen })}
    >
      <Time isPlayerAnswered={!!playerAnswerValue} />

      <Modal
        slotProps={{
          backdrop: {
            style: {
              backgroundColor: "rgba(0, 0, 0, 0.8)",
            },
          },
        }}
        open={isTrap.code}
      >
        <CodeForm userID={userID} />
      </Modal>

      {room && !room.currentQuestion && (
        <div className={S.WaitingContainer}>
          <div className={S.SliderText}>
            {user.name} masz chwilę ? Wybierz swoją postać...
          </div>
          <SliderAvatars user={user} />

          {isReady ? (
            <div className={S.Text}>
              ...rozgrzej nadgarstki za chwilę startujemy 🛫
            </div>
          ) : (
            <Button
              className={S.ReadyButton}
              variant="outlined"
              color={"success"}
              size="large"
              onClick={onSetReadyButton}
            >
              <TaskAltIcon sx={{ color: "green" }} />
              Gotowy ! 😎
            </Button>
          )}
          <SocialLinks fontSize="small" />
        </div>
      )}

      <div className={S.Logo}>
        <img src={logo} alt="logo" width={200} />
      </div>

      {room?.currentQuestion?.answers && !room.isQuizEnd && (
        <div className={S.OptionsContainer}>
          {manipulatedAnswers.map((answer) => (
            <Button
              key={answer}
              className={S.Option}
              variant="outlined"
              color={playerAnswerValue === answer ? "info" : "primary"}
              size="large"
              onClick={onChosenAnswer(answer)}
              disabled={
                room.currentQuestion.isFinished ||
                (isTrap.fiftyFifty && isNotOptionFirstOrSecond(answer, room))
              }
              sx={{
                "&:disabled": {
                  ...(isThisCorrectAnswer(answer) && {
                    border: "1px solid green",
                  }),
                },
                "&::after": {
                  ...(isTrap.votes && {
                    width: `${percentsOfAnswers.percentageDistribution[answer]}% !important`,
                    background:
                      percentsOfAnswers.highestAnswer === answer
                        ? "linear-gradient(90deg, rgba(14,0,255,0) 0%, rgba(0,255,46,0.2) 100%);"
                        : "linear-gradient(90deg, rgba(14,0,255,0) 0%, rgba(0,159,255,0.2) 100%);",
                  }),
                },
              }}
            >
              {fillAnswerWithTraps(answer, isTrap)}
            </Button>
          ))}
        </div>
      )}

      {room && room.currentQuestion && playerAnswerValue && !room.isQuizEnd && (
        <div className={cx(S.OptionsInfo, "animate__animated animate__bounce")}>
          Mamy twoją odpowiedź, ale czy na pewno jesteś jej pewn
          {H.getGender(user.name) === "W" ? "a" : "y"} ? 🙄
        </div>
      )}

      {room && room.currentQuestion && !room?.currentQuestion?.answers && (
        <>
          <div className={S.OptionsInfoWrapper}>
            <TimeShort />
            <div className={cx(S.OptionsInfoShowUp)}>
              Za chwilę pojawią się odpowiedzi
            </div>
          </div>
          <div
            className={cx(
              S.OptionsInfoTraps,
              "animate__animated animate__bounce animate__delay-5s",
            )}
          >
            Wykorzystaj ten czas na podłożenie komuś miny <div>😵</div>
            <img
              className={cx(S.Arrow, {
                [S.IsArrowHidden]: window.innerHeight <= 550,
              })}
              src={arrow}
              alt="arrow"
            />
          </div>
        </>
      )}

      {room?.isQuizEnd && (
        <div className={S.EndInfo}>
          <div className={S.EndInfoText}>
            Spodobało Ci się? Zostaw znak, żebyśmy wiedzieli, że tu byłeś!
            <div className={S.SocialsContainer}>
              <SocialLinks relative />
            </div>
          </div>
        </div>
      )}

      {room?.currentQuestion &&
        !room?.currentQuestion.isFinished &&
        !room?.isQuizEnd && <ChooseTrapsSnack userID={userID} />}

      <TrapsNotification userID={userID} />
    </div>
  );
};

export default PlayerView;
