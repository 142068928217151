import { FC } from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import * as S from "./styles";

const fontSizeOpt = {
  small: "40px",
  big: "60px",
};

interface SocialLinksProps {
  relative?: boolean;
  fontSize?: "small" | "big";
}

export const SocialLinks: FC<SocialLinksProps> = ({
  relative,
  fontSize = "big",
}) => (
  <S.Container $relative={relative}>
    <a
      className="IntroPage__fb_link"
      href="https://www.facebook.com/profile.php?id=61566688384376"
      target="_blank"
    >
      <FacebookIcon sx={{ color: "white", fontSize: fontSizeOpt[fontSize] }} />
    </a>
  </S.Container>
);
