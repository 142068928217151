import React, { useRef } from "react";
import cx from "classnames";
import useAutoScrollVert from "../../../../../../helpers/useAutoScrollVert";
import { IRoom, IUserIn, Traps } from "../../../../../../helpers/types";
import * as H from "../../../../helpers";
import * as C from "../../../../../../helpers/constants";
import S from "./styles.module.scss";

interface PlayerProps {
  user: IUserIn;
  room: IRoom;
  i: number;
}

const Player: React.FC<PlayerProps> = ({ user, room, i }) => {
  const trapsContainerRef = useRef<HTMLDivElement>(null);
  useAutoScrollVert(trapsContainerRef);

  const ownTraps = Object.keys(
    room?.currentQuestion?.userTraps?.[user.id] || {},
  ) as Traps[];

  return (
    <div className={S.player} key={user.id}>
      <img
        className={cx(S.avatar, {
          isOpacity: room?.currentQuestion
            ? !H.isPlayerIdAnswered(room, user.id)
            : !H.getUserFromUsersIn(user.id, room)?.isReady,
        })}
        src={`media/avatars/${H.getGender(user.name)}/${user.avatarID}.svg`}
        alt="avatar"
      />
      <div className={S.name}>
        <span>{[0, 1, 2].includes(i) && `${i + 1}.`}</span> {user.name}
      </div>
      <div className={S.trapsContainer} ref={trapsContainerRef}>
        {ownTraps?.map((ownTrap) => {
          const ownTrapConfig = C.trapList[ownTrap];
          const Icon = ownTrapConfig?.icon;

          return (
            <div key={ownTrap} className={S.trap}>
              <Icon color={ownTrapConfig.isPositive ? "success" : "primary"} />
            </div>
          );
        })}
      </div>
      <div className={S.points}>{user.points}</div>
    </div>
  );
};

export default Player;
