import React, { useEffect } from "react";

const useAutoScrollVert = (ref: React.RefObject<HTMLDivElement>) => {
  useEffect(() => {
    const element = ref?.current;
    let scrollDirectionRight = true;
    const scrollInterval = setInterval(() => {
      element?.scrollBy(scrollDirectionRight ? 2 : -1.5, 0);
      if (element?.offsetParent) {
        if (
          element.scrollWidth - Math.round(element.scrollLeft) <=
          element.clientWidth + 5
        ) {
          scrollDirectionRight = false;
        } else if (Math.round(element.scrollLeft) < 1) {
          scrollDirectionRight = true;
        }
      }
    }, 30);

    return () => {
      clearInterval(scrollInterval);
    };
  }, [ref]);

  return null;
};

export default useAutoScrollVert;
