import React, { useContext, useEffect, useState } from "react";
import cx from "classnames";
import { useTimer } from "react-timer-hook";
import { timeCountSec } from "../../helpers/constants";
import { RoomContext } from "src/modules/RoomPage/RoomPage";
import "./styles.scss";

interface TimeProps {
  isPlayerAnswered?: boolean;
  onTimerExpaired?(): void;
}

const Time: React.FC<TimeProps> = ({ isPlayerAnswered, onTimerExpaired }) => {
  const { room } = useContext(RoomContext);
  const [isShadowTimer, setIsShadowTimer] = useState(false);

  const time = new Date();
  time.setSeconds(time.getSeconds() + timeCountSec);

  const onExpire = () => {
    setIsShadowTimer(false);
    onTimerExpaired?.();
  };

  const { pause, restart } = useTimer({
    expiryTimestamp: time,
    onExpire,
  });

  useEffect(() => {
    restart(time, false);
  }, []);

  useEffect(() => {
    if (room?.currentQuestion?.qtime) {
      restart(time, true);
      setIsShadowTimer(true);
    }
  }, [room?.currentQuestion?.qtime]);

  useEffect(() => {
    if (room?.currentQuestion?.isFinished || isPlayerAnswered) {
      pause();
      setIsShadowTimer(false);
    }
  }, [room?.currentQuestion?.isFinished, isPlayerAnswered]);

  return (
    <>
      {!onTimerExpaired && (
        <div
          className={cx("shadowBox", { isShadowTimer })}
          style={{
            animationDelay: `${timeCountSec - 10}s`,
          }}
        />
      )}
      {onTimerExpaired && isShadowTimer && (
        <div className="timerCount animate__animated animate__backInLeft"></div>
      )}
    </>
  );
};

export default Time;
