import React, { useContext, useEffect, useState } from "react";
import cx from "classnames";
import difference from "lodash.difference";
import { Traps } from "../../helpers/types";
import { RoomContext } from "src/modules/RoomPage/RoomPage";
import * as H from "../../modules/RoomPage/helpers";
import * as C from "../../helpers/constants";
import S from "./styles.module.scss";

interface TrapsNotificationProps {
  userID: string;
}

const TrapsNotification: React.FC<TrapsNotificationProps> = ({ userID }) => {
  const { room } = useContext(RoomContext);
  const [trapsToShow, setTrapsToShow] = useState<Traps[]>([]);
  const ref = React.useRef<Record<Traps, { userFromID: string }>>();

  const traps = room?.currentQuestion?.userTraps?.[userID];

  const closeModal = () => {
    setTrapsToShow([]);
  };

  useEffect(() => {
    if (!traps) {
      closeModal();
    }
  }, [traps]);

  useEffect(() => {
    if (traps) {
      if (ref.current) {
        const diff = difference(
          Object.keys(traps),
          Object.keys(ref.current),
        ) as Traps[];
        setTrapsToShow((prev: Traps[]) => [...prev, ...diff]);
      } else {
        setTrapsToShow(Object.keys(traps) as Traps[]);
      }
    }
    ref.current = traps;
  }, [traps]);

  const trapsWithValues = trapsToShow.map((trapName) => {
    if (traps?.[trapName] && traps?.[trapName].userFromID) {
      const { name, avatarID } = H.getUserFromUsersIn(
        traps?.[trapName].userFromID,
        room,
      )!;
      const { title, isPositive } = C.trapList[trapName as Traps];

      return {
        title,
        userName: name,
        userAvatar: avatarID,
        isPositive: !!isPositive,
      };
    }
    return undefined;
  });

  return (
    <>
      {!!trapsToShow.length && (
        <div className={S.Container} onClick={closeModal}>
          {trapsWithValues
            .filter((a) => a !== undefined)
            .map((trap) => (
              <div
                className={cx(
                  S.TrapWrapper,
                  { [S.IsPositive]: trap.isPositive },
                  "animate__animated animate__backInLeft animate__delay-0s",
                )}
                key={trap.title}
              >
                <div className={S.TrapName}>{trap.title}</div>
                od
                <div className={S.UserWrapper}>
                  <img
                    className={S.Avatar}
                    src={`media/avatars/${H.getGender(trap.userName)}/${trap.userAvatar}.svg`}
                    alt="avatar"
                  />
                  <div>{trap.userName}</div>
                </div>
              </div>
            ))}
        </div>
      )}
    </>
  );
};

export default TrapsNotification;
