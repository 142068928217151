import React, { useContext, useState } from "react";
import cx from "classnames";
import { Traps } from "../../helpers/types";
import Drawer from "@mui/material/Drawer";
import PixIcon from "@mui/icons-material/Pix";
import ConfirmIcon from "../../assets/confirm.svg";
import S from "./styles.module.scss";
import * as API from "../../api/roomAPI";
import * as C from "../../helpers/constants";
import * as H from "../../modules/RoomPage/helpers";
import { RoomContext } from "src/modules/RoomPage/RoomPage";

interface TrapsProps {
  userID: string;
}

const ChooseTrapsSnack: React.FC<TrapsProps> = ({ userID }) => {
  const { room } = useContext(RoomContext);
  const [isExpanded, setIsExpanded] = useState(false);
  const [userIdToConfirm, setUserIdToConfirm] = useState<string>();
  const [trapChosen, setTrapChosen] = useState<Traps>();
  const ownTraps = room?.usersIn?.find((user) => user.id === userID)?.ownTraps;
  const ownTrapsGroupByCount = ownTraps
    ? Object.entries(
        ownTraps.reduce((acc, item) => {
          acc[item] = (acc[item] || 0) + 1;
          return acc;
        }, {}),
      ).map(
        ([name, count]) => ({ name, count }) as { name: string; count: number },
      )
    : [];

  const onTrapClick = (trap: Traps) => () => {
    setTrapChosen(trap);
  };

  const onUserClick = (id: string) => () => {
    setUserIdToConfirm(id);
  };

  const onCloseDrawer = () => {
    setIsExpanded(false);
    setTimeout(() => {
      setTrapChosen(undefined);
      setUserIdToConfirm(undefined);
    }, 200);
  };

  const onUserConfirmClick = () => {
    API.setTrapForUser(userIdToConfirm!, trapChosen!, userID!, room!);
    onCloseDrawer();
  };

  const isUserToChooseDisabled = (id: string, trapName: Traps) => {
    if (C.trapList[trapName].isPositive) {
      return false;
    }
    if (H.hasUserThisTrap(id, C.trapList.shield.name, room)) {
      return true;
    }
    if (H.hasUserThisTrap(id, trapName, room)) {
      return true;
    }
  };

  return (
    <div className={S.Container}>
      <div
        className={S.OpenButton}
        onClick={() => setIsExpanded((prev) => !prev)}
      >
        <PixIcon />
      </div>

      <Drawer anchor={"bottom"} open={isExpanded} onClose={onCloseDrawer}>
        {!trapChosen ? (
          <div className={S.DrawerWithTraps}>
            {ownTrapsGroupByCount?.map((ownTrap) => {
              const ownTrapConfig = C.trapList[ownTrap.name];
              const Icon = ownTrapConfig?.icon;

              return (
                <div
                  key={ownTrap.name}
                  className={S.TrapContainer}
                  onClick={onTrapClick(ownTrap.name as Traps)}
                >
                  {ownTrap.count > 1 && (
                    <span className={S.TrapCount}>{ownTrap.count}</span>
                  )}
                  <Icon
                    color={ownTrapConfig.isPositive ? "success" : "primary"}
                  />
                  <div className={S.TrapTitle}>{ownTrapConfig.title}</div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className={S.DrawerWithUsers}>
            <div className={S.UsersContainer}>
              {room?.usersIn
                .filter((user) => {
                  const trapChosenConfig = C.trapList[trapChosen];
                  return trapChosenConfig.isPositive
                    ? user.id === userID
                    : user.id !== userID;
                })
                .map((user) =>
                  user.id !== userIdToConfirm ? (
                    <div
                      key={user.id}
                      className={cx(S.User, {
                        [S.IsDisabled]: isUserToChooseDisabled(
                          user.id,
                          trapChosen,
                        ),
                      })}
                      onClick={
                        isUserToChooseDisabled(user.id, trapChosen)
                          ? undefined
                          : onUserClick(user.id)
                      }
                    >
                      <img
                        className={S.Avatar}
                        src={`media/avatars/${H.getGender(user.name)}/${user.avatarID}.svg`}
                        alt="avatar"
                      />
                      <div>{user.name}</div>
                    </div>
                  ) : (
                    <div
                      key={user.id}
                      className={S.User}
                      onClick={onUserConfirmClick}
                    >
                      <img
                        className={S.AvatarConfirmUser}
                        src={ConfirmIcon}
                        alt="confirm icon"
                      />
                      <div className={S.Confirm}>Potwierdź</div>
                    </div>
                  ),
                )}
            </div>
          </div>
        )}
      </Drawer>
    </div>
  );
};

export default ChooseTrapsSnack;
