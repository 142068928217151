import React from "react";
import { IUserWithPoints } from "../../helpers/types";
import cupIcon from "../../assets/cup.svg";
import medalIcon from "../../assets/medal.svg";
import loserPng from "../../assets/loser.png";
import * as H from "../../modules/RoomPage/helpers";
import "./styles.scss";
import "animate.css";

interface ScoreBoardProps {
  userWithPoints: IUserWithPoints[];
}

const ScoreBoard: React.FC<ScoreBoardProps> = ({ userWithPoints }) => {
  const restUserWithPoints = [...userWithPoints];

  const winnersLength = restUserWithPoints.filter((user) => user.points).length;
  const winnersMax3Count = winnersLength >= 3 ? 3 : winnersLength;
  const onlyMax3Winners = restUserWithPoints.splice(0, winnersMax3Count);

  return (
    <div className="ScoreBoard__players_container">
      <div className="ScoreBoard__players_container__best_stand animate__animated animate__slideInDown">
        {onlyMax3Winners[1] && (
          <div
            key={onlyMax3Winners[1].id}
            className="ScoreBoard__players_container__best_stand__player"
          >
            <img
              src={`media/avatars/${H.getGender(onlyMax3Winners[1].name!)}/${onlyMax3Winners[1].avatarID}.svg`}
              width={120}
              alt="avatar"
            />
            <div className="ScoreBoard__players_container__best_stand__player__name">
              <img src={medalIcon} alt="medal" width={30} />
              {onlyMax3Winners[1].name}
              <img src={medalIcon} alt="medal" width={30} />
            </div>
            <div className="ScoreBoard__players_container__points animate__animated animate__flip animate__delay-2s">
              + {onlyMax3Winners[1].points}
            </div>
          </div>
        )}
        {onlyMax3Winners[0] && (
          <div
            key={onlyMax3Winners[0].id}
            className="ScoreBoard__players_container__best_stand__winner"
          >
            <img
              src={`media/avatars/${H.getGender(onlyMax3Winners[0].name!)}/${onlyMax3Winners[0].avatarID}.svg`}
              width={160}
              alt="avatar"
            />

            <div className="ScoreBoard__players_container__best_stand__player__name">
              <img src={cupIcon} alt="cup" width={60} />
              {onlyMax3Winners[0].name}
              <img src={cupIcon} alt="cup" width={60} />
            </div>
            <div className="ScoreBoard__players_container__points animate__animated animate__flip animate__delay-3s">
              + {onlyMax3Winners[0].points}
            </div>
          </div>
        )}
        {onlyMax3Winners[2] && (
          <div
            key={onlyMax3Winners[2].id}
            className="ScoreBoard__players_container__best_stand__player"
          >
            <img
              src={`media/avatars/${H.getGender(onlyMax3Winners[2].name!)}/${onlyMax3Winners[2].avatarID}.svg`}
              width={120}
              alt="avatar"
            />
            <div className="ScoreBoard__players_container__best_stand__player__name">
              <img src={medalIcon} alt="medal" width={30} />
              {onlyMax3Winners[2].name}
              <img src={medalIcon} alt="medal" width={30} />
            </div>
            <div className="ScoreBoard__players_container__points animate__animated animate__flip animate__delay-1s">
              + {onlyMax3Winners[2].points}
            </div>
          </div>
        )}
      </div>

      <div className="ScoreBoard__players_container__losers_stand animate__animated animate__jackInTheBox">
        {winnersLength === 0 ? (
          <div className="ScoreBoard__players_container__losers_stand__all_lost_container">
            <img src={loserPng} alt="loser" />
            <div>Wszyscy bez punktów 😭</div>
          </div>
        ) : (
          <div className="ScoreBoard__players_container__losers_stand__lost_container">
            {restUserWithPoints.map((user) => (
              <div
                key={user.id}
                className="ScoreBoard__players_container__losers_stand__lost_container__player"
              >
                <div
                  className="ScoreBoard__players_container__player__lose_player"
                  key={user.id}
                >
                  {user.name} {!!user.points && "+"}
                  {user.points} {user.points ? "😜" : "😥"}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ScoreBoard;
