import React, { useEffect } from "react";

const useAutoScroll = (
  listRef: React.RefObject<HTMLDivElement>,
  isActive: boolean,
) => {
  useEffect(() => {
    if (!isActive) return;

    // SCROLL FUNCTIONALITY
    const element = listRef?.current;
    let scrollDirectionBottom = true;
    const scrollInterval = setInterval(() => {
      element?.scrollBy(0, scrollDirectionBottom ? 2 : -1.5);

      if (element?.offsetParent) {
        if (
          element.scrollHeight - Math.round(element.scrollTop) <=
          element.clientHeight + 5
        ) {
          scrollDirectionBottom = false;
        } else if (Math.round(element.scrollTop) < 1) {
          scrollDirectionBottom = true;
        }
      }
    }, 30);

    return () => {
      clearInterval(scrollInterval);
    };
  }, [listRef, isActive]);

  return null;
};

export default useAutoScroll;
