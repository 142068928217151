import React, { useContext, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import cx from "classnames";
import Button from "@mui/material/Button";
import { categoriesIcons } from "src/helpers/constants";
import { RoomContext } from "src/modules/RoomPage/RoomPage";
import useUserAnswerPoints from "../../../../../helpers/useUserAnswerPoints";
import QuestionScoreBoard from "../../../../../components/QuestionScoreBoard/QuestionScoreBoard";
import logo from "../../../../../assets/logo.png";
import * as API from "../../../../../api/roomAPI";
import "./styles.scss";

const LeftSide: React.FC = () => {
  const { room, categories } = useContext(RoomContext);
  const { ID: roomID } = useParams() as { ID: string };
  const [categoryChosen, setCategoryChosen] = useState<string>();
  const sectionRef = useRef<HTMLDivElement>(null);

  const { userWithPoints } = useUserAnswerPoints(roomID);

  const onChosenCategory = (category: string) => () => {
    setCategoryChosen(category);
    API.pushCategoryWithQuestions(roomID!, category);
  };

  const { currentQuestion: cQ, questions } = room || {};

  return (
    <div className={cx("Left_side", { isQuizStared: room?.currentQuestion })}>
      <div className="Left_side__container">
        {!room?.currentQuestion ? (
          <>
            <div className="Left_side__container__categories">
              <div className="Left_side__container__categories__wrapper">
                <div>Wybierz kategorie: </div>
                <div
                  className="Left_side__container__categories__wrapper__scroll"
                  ref={sectionRef}
                >
                  {categories?.map((category) => {
                    const Icon =
                      categoriesIcons[category.name] ?? categoriesIcons.simply;
                    return (
                      <Button
                        key={category.name}
                        sx={{
                          backgroundColor: category.color,
                          "&:hover": {
                            backgroundColor: category.color,
                            boxShadow:
                              categoryChosen === category.name
                                ? "0 0 45px orange inset"
                                : "none",
                          },
                          border:
                            categoryChosen === category.name
                              ? "3px solid #e0a002"
                              : "3px solid transparent",
                          boxShadow:
                            categoryChosen === category.name
                              ? "0 0 45px orange inset"
                              : "none",
                        }}
                        size="large"
                        variant="contained"
                        onClick={onChosenCategory(category.name)}
                      >
                        <img src={Icon} alt={`${category.title} icon`} />
                        <p>{category.title}</p>
                      </Button>
                    );
                  })}
                </div>
              </div>
            </div>
          </>
        ) : cQ?.isFinished ? (
          <QuestionScoreBoard
            userWithPoints={userWithPoints}
            usersIn={room.usersIn}
          />
        ) : (
          <>
            <div className="Left_side__container__question_number">
              {cQ!.qNumber + 1}/{questions?.length}
            </div>
            {room?.currentQuestion?.imageUrl && (
              <div className="Left_side__container__image_container">
                <img
                  className="Left_side__container__image_container__image"
                  src={room?.currentQuestion?.imageUrl}
                  alt="questionImage"
                />
              </div>
            )}
            <div
              className={`${!!cQ?.imageUrl ? "Left_side__container__question_with_image" : "Left_side__container__question"}`}
            >
              {room?.currentQuestion?.question}
            </div>

            <img
              className="Left_side__container__logo"
              src={logo}
              alt="logo"
              width={200}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default LeftSide;
