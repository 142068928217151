/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import QRCode from "react-qr-code";
import LinkIcon from "@mui/icons-material/Link";
import Face from "@mui/icons-material/Face";
import Backdrop from "@mui/material/Backdrop";
import Button from "@mui/material/Button";
import FlagIcon from "@mui/icons-material/Flag";
import Player from "./Player/Player";
import useAutoScroll from "../../../../../helpers/useAutoScroll";
import useAutoScrollVert from "../../../../../helpers/useAutoScrollVert";
import { RoomContext } from "src/modules/RoomPage/RoomPage";
import * as API from "../../../../../api/roomAPI";
import "./styles.scss";
import { Tooltip } from "@mui/material";
import cx from "classnames";

const RightSide: React.FC = () => {
  const { room } = useContext(RoomContext);
  const listRef = useRef<HTMLDivElement>(null);
  const trapsContainerRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = React.useState(false);
  const { ID: roomID } = useParams() as { ID: string };
  useAutoScroll(listRef, !room?.currentQuestion);
  useAutoScrollVert(trapsContainerRef);

  const handleClose = () => {
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  const onStartQuizButton = () => {
    API.pushQuestionReq(roomID!, room!);
  };

  const isRoomReadyToStart =
    room?.usersIn &&
    room?.usersIn?.length >= 2 &&
    room.questions;

  return (
    <div className="Right_side">
      <div className="Right_side__container">
        <div
          className={cx("Right_side__container__top_side", {
            isRoomActive: room?.currentQuestion,
          })}
        >
          {!room?.currentQuestion && (
            <div className="Right_side__container__top_side__connect_wrapper">
              <div className="Right_side__container__top_side__connect_wrapper__room_number">
                <LinkIcon />
                Pokój nr:
                <span className="Right_side__container__top_side__connect_wrapper__room_number__id">
                  {" "}
                  {roomID}
                </span>
              </div>
              <QRCode
                style={{ height: "auto", maxWidth: "70%", width: "100%" }}
                value={window.location.href}
                onClick={handleToggle}
              />

              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={open}
                onClick={handleClose}
              >
                <QRCode value={window.location.href} />
              </Backdrop>
            </div>
          )}

          <div className="Right_side__container__top_side__players_container">
            <div className="Right_side__container__top_side__players_container__title">
              <Face /> Quizowicze:
            </div>

            <div
              className="Right_side__container__top_side__players_container__players"
              ref={listRef}
            >
              {room?.usersIn
                ?.sort((a, b) => b.points - a.points)
                ?.map((user, i) => (
                  <Player i={i} key={user.id} user={user} room={room} />
                ))}
              {(!room?.usersIn || room?.usersIn?.length < 2) && (
                <div className="Right_side__container__top_side__players_container__players__warning">
                  ...minimum 2 graczy
                </div>
              )}
            </div>
          </div>
        </div>

        {!room?.currentQuestion && (
          <Tooltip
            title={
              !isRoomReadyToStart
                ? "Minimum 2 graczy, którzy są ready i wybierz kategorie. Inaczej nie ruszymy dalej 😥"
                : ""
            }
            arrow
            placement="top"
          >
            <div>
              <Button
                sx={{ color: "white" }}
                variant="contained"
                color="info"
                size="large"
                startIcon={<FlagIcon />}
                disabled={!isRoomReadyToStart}
                onClick={onStartQuizButton}
              >
                Startujemy
              </Button>
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default RightSide;
