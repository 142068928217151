import { useEffect, useState } from "react";
import { ref, onValue } from "firebase/database";
import { uid } from "uid";
import { FieldValues, useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
// import { useSwipeable } from "react-swipeable";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { Icon, TextField, ThemeProvider } from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import PasswordIcon from '@mui/icons-material/Password';
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { SocialLinks } from "src/components/SocialLinks/SocialLinks";
// import { Instructions } from "src/components/Instructions/Instructions";
import { db } from "../../firebase";
import { IRoom } from "../../helpers/types";
import { hostTheme } from "../../helpers/constants";
import logo from "../../assets/logo.png";
import thinkingMp3 from "../../assets/thinking.mp3";
import * as API from "../../api/introAPI";
import "./styles.scss";

const IntroPage = () => {
  const { state } = useLocation<{ roomID: string }>();
  const roomIDUrlState = state?.roomID;
  const [rooms, setRooms] = useState<IRoom[]>();
  const [entranceWay, setEntranceWay] = useState<number>(
    roomIDUrlState ? 2 : 0,
  );
  // const [open, setOpen] = useState(true);

  // const handlers = useSwipeable({
  //   onSwiped: (eventData) => {
  //     console.log("User Swiped!", eventData);
  //     if (eventData.dir === "Right") {
  //       setOpen(true);
  //     }
  //   },
  // });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const history = useHistory();

  useEffect(() => {
    const unsubscribeRooms = onValue(ref(db, "rooms/"), (snapshot) => {
      const data = snapshot.val();
      process.env.NODE_ENV !== "production" && console.log("rooms", data);
      setRooms(data);
    });

    return () => {
      unsubscribeRooms();
    };
  }, []);

  const createRoom = () => {
    const userID = uid();
    const roomID = Math.floor(Math.random() * 9000 + 999);

    const thinkingAudio = new Audio(thinkingMp3);
    thinkingAudio.volume = 1;
    thinkingAudio.loop = true;
    thinkingAudio.play();

    API.createRoomReq(roomID, userID);
    history.push(`/${roomID}`, { user: userID });
  };

  const joinToRoom = (data: FieldValues) => {
    const { name, roomID } = data;

    if (rooms && rooms[roomID]) {
      const userID = uid();

      API.joinRoomReq(rooms, roomID, userID, name);
      history.push(`/${roomID}`, { user: userID });
    }
  };

  return (
    <ThemeProvider theme={hostTheme}>
      {/* <div {...handlers} className="IntroPage__container"> */}
      <div className="IntroPage__container">
        <img src={logo} alt="logo" width={300} />
        {!entranceWay && (
          <>
            <div className="IntroPage__buttons_container">
              <div className="IntroPage__buttons_container__create_join">
                1. Odpal na telewizorze / komputerze i...
                <Button
                  variant="outlined"
                  color="info"
                  size="large"
                  endIcon={<AddIcon />}
                  onClick={createRoom}
                >
                  Stwórz
                </Button>
              </div>

              <div className="IntroPage__buttons_container__create_join">
                2. Poprzez telefon...
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  endIcon={<AttachFileIcon />}
                  onClick={() => setEntranceWay(2)}
                >
                  Dołącz
                </Button>
              </div>
            </div>
            <div className="IntroPage__config-buttons">
              <HealthAndSafetyIcon fontSize="small" color="success" onClick={() => window.allPositive = true} />
              <PasswordIcon fontSize="small" color="warning" onClick={() => window.noCodeTrap = true} />
            </div>

          </>
        )}

        {entranceWay === 2 && (
          <form
            className="IntroPage__form_container"
            onSubmit={handleSubmit(joinToRoom)}
          >
            <TextField
              id="standard-basic"
              label="Imię"
              variant="outlined"
              autoFocus
              {...register("name", { required: true, maxLength: 10 })}
            />
            {errors?.name?.type === "required" && (
              <span className="IntroPage__form_container__error">
                O czymś zapomnieliśmy
              </span>
            )}
            {errors?.name?.type === "maxLength" && (
              <span className="IntroPage__form_container__error">
                Takie długie ?
              </span>
            )}

            <TextField
              id="standard-basic"
              label="Numer pokoju"
              variant="outlined"
              defaultValue={roomIDUrlState ?? ""}
              {...register("roomID", { required: true })}
            />
            {errors?.roomID?.type === "required" && (
              <span className="IntroPage__form_container__error">
                O czymś zapomnieliśmy
              </span>
            )}

            <Button
              variant="outlined"
              color="primary"
              size="large"
              type="submit"
              endIcon={<NavigateNextIcon />}
            >
              To lecimy
            </Button>
          </form>
        )}
        <div className="IntroPage__social_links">
          <SocialLinks fontSize="small" />
        </div>
        {/* <Instructions open={open} setOpen={setOpen} /> */}
      </div>
    </ThemeProvider >
  );
};

export default IntroPage;
