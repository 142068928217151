import { IRoom, IUserAnswers, Traps } from "src/helpers/types";
import * as C from "../../../helpers/constants";
import * as H from "../helpers";

export const getActiveTraps = (
  userID: string,
  room: IRoom,
): Partial<Record<Traps, boolean>> => ({
  mirrorWord: H.hasUserThisTrap(userID, C.trapList.mirrorWord.name, room),
  hideLetters: H.hasUserThisTrap(userID, C.trapList.hideLetters.name, room),
  rotateScreen: H.hasUserThisTrap(userID, C.trapList.rotateScreen.name, room),
  fiftyFifty: H.hasUserThisTrap(userID, C.trapList.fiftyFifty.name, room),
  doubling: H.hasUserThisTrap(userID, C.trapList.doubling.name, room),
  code: H.hasUserThisTrap(userID, C.trapList.code.name, room),
  votes: H.hasUserThisTrap(userID, C.trapList.votes.name, room),
});

export const fillAnswerWithTraps = (
  answer: string,
  isTrap: Partial<Record<Traps, boolean>>,
) => {
  let answerResults = answer;

  if (isTrap.mirrorWord) {
    answerResults = answerResults.split("").reverse().join("");
  }
  if (isTrap.hideLetters && answerResults.length >= 4) {
    answerResults = answerResults
      .split("")
      .map((char, n) => (n % 3 === 0 ? " " : char))
      .join("");
  }
  return answerResults;
};

export const getPercentsOfAnswers = (userAnswers: IUserAnswers) => {
  const answerCounts = {};
  let totalVotes = 0;

  for (const key in userAnswers) {
    const answerName = userAnswers[key].name;
    answerCounts[answerName] = (answerCounts[answerName] || 0) + 1;
    totalVotes++;
  }

  const percentageDistribution = {};
  for (const answer in answerCounts) {
    percentageDistribution[answer] = (answerCounts[answer] / totalVotes) * 100;
  }

  let highestPercentage = 0;
  const highestAnswers = [];

  for (const percentage of Object.values(percentageDistribution) as number[]) {
    if (percentage > highestPercentage) {
      highestPercentage = percentage;
    }
  }

  for (const answer in percentageDistribution) {
    if (percentageDistribution[answer] === highestPercentage) {
      highestAnswers.push(answer);
    }
  }

  const highestAnswer = highestAnswers.length > 1 ? null : highestAnswers[0];

  return { percentageDistribution, highestAnswer };
};
